@font-face {
  font-family: 'SUIT-Medium';
  src: url('./assets/fonts/SUIT-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('./assets/fonts/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* #2b66bd */
:root {
  --color-main: rgb(85, 87, 92);
  --color-main2: #1B1B1B;

  --color-high1: #660529;
  --color-high2: #7F9F80;
  --color-high3: #F9E897;
  --color-high4: #FFC374;
  --color-hyper: #0275d8;

  --color-high-rgb: rgb(0, 198, 207);

  --font-main: 'SUIT-Medium';
  --font-sub: 'SUIT-Regular';

  --border-header: #828590;
  --border-main: #E7E6E8;
  --border-sub: #e4e8eb;
  --border-goo: #fcfcfc;
  --border-result: #e8ebee;

  --js-color: #1a3866;

  --blue-color-1: #ccd9f6;
  --blue-color-2: #aac1f0;

  --chroma-green: rgb(0, 255, 27);

  --font-color-1: rgb(205, 205, 205);

  --height-menu: 45px;

  --box-shadow-b5: 0 2px 5px 1px rgb(64 60 67 / 16%);
  --box-shadow-2: -1px 0 6px rgb(0 0 0 / 20%);
  --box-shadow-3: 0 1px 4px rgb(32 33 36 / 10%);
  --box-shadow-3-hover: 0 1px 5px rgb(0 198 207 / 32%);
  --box-shadow-header-hover: 0 3px 6px rgb(0 198 207 / 32%);
  --box-shadow-b3: 0 1px 3px 0 rgba(0, 0, 0, .12);
  --box-shadow-home: 0 2px 6px 0 rgba(34, 36, 38, .04);

  --home-left-width: 800px;
  --home-right-width: 350px;
}

.App {
  font-family: "Pretendard JP", Pretendard, -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", "Noto Sans KR", "Noto Sans CJK KR", "NanumBarunGothic", Roboto, "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.fBlank {
  flex: 1 0 auto;
}

:root {
  --header-top-height: 50px;
  --header-bot-height: 40px;
}



.Title {
  padding: 12px;
}

.Title .title {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}

.Title .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 16px;
}

.Title .btns .cover {
  display: flex;
  align-items: center;
  border: solid 1px var(--border-main);
  border-radius: 4px;
}

.Title .btns .cover .btn {
  display: flex;
  align-items: center;

  width: fit-content;
  min-width: fit-content;

  padding: 4px 12px;
}

.Title .btns .cover .btn.middle {
  border-left: solid 1px var(--border-main);
  border-right: solid 1px var(--border-main);
}

.Title .btns .cover .btn .i {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 15px;
  fill: #555;
}

.Title .btns .cover .btn .i.lock {
  margin-left: 4px;
  height: 13px !important;
  fill: #999 !important;
}

.Title .btns .cover .btn .t {
  min-width: fit-content;

  font-size: 15px;
  line-height: 26px;

  margin-left: 8px;
}

.Title .btns .cover.isolation {
  margin-left: 8px;
}

.Title .btns .cover.isolation .btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10px;
  height: 26px;
}

.Result {
  display: flex;
  flex-direction: column;

  padding: 12px;
}

.Result .Info {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  border: solid 2px var(--color-high1);
}

.Result .Info .mid {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.title_top {
  background-color: var(--color-high1);
  color: #fff;
  font-weight: 600;

  font-size: 15px;
  line-height: 22.5px;
  padding: 5px 10px;

  border-bottom: solid 1px #fff;
}

.Info .title {
  background: var(--color-high2);
  color: #fff;
  padding: 5px 10px;

  font-size: 15px;
  line-height: 22.5px;
  font-weight: 600;
}

.Info .title .t2 {
  font-size: 19px;
  line-height: 26px;
}

.Info .img {
  display: flex;

  position: relative;
  overflow: hidden;
}

.Info .img>img {
  width: 100%;
}

.Info .block {
  display: flex;

  border-bottom: solid 1px var(--border-main);
}

.Info .block.end {
  border-bottom: none;
}

.Info .block .tag {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 5px 10px;
  min-width: 100px;
  width: 100px;

  background-color: #0171BB;
  color: #fff;
  font-weight: 600;
  font-size: 15px;

  border-spacing: 2px;
}

.t.bb {
  border-bottom: solid 1px var(--border-main);
}

.Info .block .text {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 5px 10px;

  line-height: 1.5;
  color: #222;
  font-size: 15px;

  width: calc(100% - 100px);
}

.t span.y {
  font-size: 12px;
}

.text_nop {
  padding: 0 !important;
}

.text_nop .ss {
  padding: 5px 10px;
}

.text .i>img {
  width: 100%;
}

.Result .Index {
  margin-top: 12px;
  border: solid 1px var(--border-main);
  padding: 12px 25px;
  padding-left: 14px;

  width: fit-content;
}

.Result .Index .title {
  margin-bottom: 20px;
  font-size: 19px;
}

.Result .Index .box {
  margin-bottom: 4px;
  font-size: 15px;
}

.Result .Index .box .s1 {
  color: var(--color-hyper);
}

.Result .Index .s_hyper {
  color: var(--color-hyper);
}

.Result .Index .box .s1:hover,
.Result .Index .s_hyper:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Result .Body .box {
  margin: 32px 0px 22px;
  font-size: 27px;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--border-main);
  
  display: flex;
}

.Result .Body .box.folding {
  opacity: .5;
}

.Result .Body .box .arrow {
  font-family: var(--font-sub);
  font-size: 20px;
  color: #555;
  height: 100%;
  box-sizing: border-box;
  padding-top: 4px;
  margin-left: 4px;
  margin-right: 8px;
  display: flex;
  align-items: flex-start;
}

.Result .Body .box .s1 {
  color: var(--color-hyper);
  margin-right: 8px;
  cursor: pointer;
}

.Result .Body .box .s3 {
  font-size: 13px;
  color: var(--color-hyper);
}

.Body .box .s2.s_hyper {
  color: var(--color-hyper);
}

.result_hyper_span {
  color: var(--color-hyper);
  cursor: pointer;
}

.result_hyper_span:hover {
  text-decoration: underline;
}