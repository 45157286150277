.Footer {
  margin-top: 24px;
  padding: 24px 12px;
  border-top: solid 1px var(--border-main);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-size: 14px;
  line-height: 20px;
}

.Footer>img {
  margin-bottom: 10px;
}

.Footer>div {
  text-align: end;
}

#navi_btn {
  position:fixed;
  right: 20px;
  bottom: 20px;

  opacity: .4;

  border-radius: 4px;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, .5);
}

#navi_btn div {
  background-color: rgba(0, 0, 0, .3);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;
}

#navi_btn div:hover {
  cursor: pointer;
}

#navi_btn #top {
  border-bottom: solid 1px rgba(0, 0, 0, .5);
}

#navi_btn div svg {
  height: 50%;
}