.Header {
  height: calc(var(--header-top-height) + var(--header-bot-height));
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

.HeaderTop {
  display: flex;
  align-items: center;

  height: var(--header-top-height);
  box-sizing: border-box;

  padding: 8px;

  background-color: var(--color-high1);
  color: #fff;
  fill: #fff;
}

.HeaderTop .logo {
  display: flex;
  align-items: center;
  overflow: hidden;

  height: 100%;
  margin: 0 8px 0 4px;
}

.HeaderTop .logo>img {
  height: 100%;
}

.HeaderTop .icon {
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderTop .icon>svg {
  height: 50%;
}

.icon.double {
  width: 80px;
}

.icon.double>svg {
  padding-right: .75em;
}

.HeaderBot {
  display: flex;
  align-items: center;

  height: var(--header-bot-height);
  box-sizing: border-box;
}

.HeaderBot .icon {
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderBot .first {
  box-sizing: border-box;
  border-right: solid 1px var(--border-main);
  border-bottom: solid 1px var(--border-main);
}

.HeaderBot .icon>svg {
  height: 40%;
}

.HeaderBot .bot_input {
  height: 100%;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  border-bottom: solid 1px var(--border-main);
}

.HeaderBot .bot_input>input {
  border: none;
  border-radius: 0;

  box-sizing: border-box;
  height: 100%;
  padding-bottom: 1px;
  padding-left: 12px;
  font-size: 16px;
}

.HeaderBot .bot_input>input:focus {
  outline: none;
}